@import './shared/styles/_vuetify-overrides.scss';























































































































.date-picker-container {
	display: flex;
	justify-content: center;
	padding: 0px;
}
